import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';
import { OfferData, OfferProps } from './get-offer.models';
import parseOffer, { ParsedOffer } from 'src/utils/parse-offer';

const getOffer =
  (http: HttpInstance) =>
  ({ offerId }: OfferProps): Promise<HttpClientResponse<ParsedOffer>> =>
    http.get(`/api/private/offer/${offerId}/composed/metadata`).then((response) => ({
      ...response,
      data: response.data ? parseOffer(response.data as OfferData) : null,
    }));

export default getOffer;
