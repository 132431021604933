import { OfferData } from 'src/utils/http-requests/get-offer';
import { ParsedOffer } from '../parse-offer.models';

export const offerData: OfferData = {
  versionKey: 'new-offer',
  offer: {
    jobOfferWebId: 3501,
    jobOfferLanguage: {
      isoCode: 'pl',
    },
    publicationDetails: {
      jobOfferUrlSegment: 'erecruiter-kontrolki-v1-cierzpiety-pow-mragowski,oferta,3501',
    },
    productAddons: {
      listingLogo: {
        enabled: false,
        logo: {
          pracujPlBasicSizeUrl: '',
        },
      },
    },
    attributes: {
      jobTitle: 'eRecruiter - kontrolki V1',
      displayEmployerName: '39,5',
      offerAbsoluteUrl: 'string',
      workplaces: [
        {
          displayAddress: 'Cierzpięty (pow. mrągowski)',
        },
      ],
      applying: {
        applyUrl: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
        referenceNumber: 'string',
        applyProxy: {
          applyUrl: 'string',
        },
        strefa: {
          formMode: 'multiple',
          formUrl: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
          multiApplying: [],
          optionalCv: false,
        },
        ats: null,
        eRecruiter: null,
      },
    },
    style: {
      offerViewLogo: {
        pracujPlBasicSizeUrl:
          'https://logos-gpcdn.gp-dev.local/loga-firm/20056881/a6070000-5d83-0015-71bf-08d86c5bb3f2_280x280.jpg',
      },
    },
  },
};

export const parsedOfferMock: ParsedOffer = {
  offerId: 3501,
  offerURLName: 'erecruiter-kontrolki-v1-cierzpiety-pow-mragowski,oferta,3501',
  jobTitle: 'eRecruiter - kontrolki V1',
  displayEmployerName: '39,5',
  workplace: 'Cierzpięty (pow. mrągowski)',
  listing: {
    enabled: false,
    logo: '',
  },
  applyLink: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
  offerApplyType: {
    strefa: {
      formMode: 'multiple',
      formUrl: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
      multiApplying: [],
      optionalCv: false,
    },
    eRecruiter: null,
    ats: null,
  },
  offerViewLogo: 'https://logos-gpcdn.gp-dev.local/loga-firm/20056881/a6070000-5d83-0015-71bf-08d86c5bb3f2_280x280.jpg',
};
